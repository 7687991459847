<template>
  <div>
    <public-header />
    <div class="wrapper">
      <div class="page-header page-header-small">
        <div
          class="page-header-image"
          data-parallax="true"
        />
        <div class="content-center mt-4">
          <div class="container">
            <h1 class="title h1">
              Services
            </h1>
          </div>
        </div>
      </div>

      <div class="container my-5">
        <div class="section-subtitle text-center mb-4">Let's connect platform</div>
        <div class="section-title text-center mb-5">
          Engage your colleagues and clients with flexible communications
        </div>

        <div class="mb-4">Let’s Connect provides a wide range of digital services to individuals and businesses across numerous industries. We are passionate about meeting each client’s specific needs and surpassing their goals. We believe that efficient business communication is an essential part of every business’ success.</div>

        <div class="mb-4">With the use of our SMS marketing software, commonly referred to as business text messaging software, we can create and carry out SMS-based marketing campaigns that target mobile devices. By sending relevant, targeted messages to your customer’s mobile devices, our software aids our clients in fostering greater brand loyalty and engagement. </div>

        <div class="mb-4">Our team of experts conducts digital promotions utilizing MMS (Multimedia Messaging Service), polls, discounts, surveys, videos, and more to target potential consumers via permission-based subscribe buttons within campaign messages. </div>

        <div class="mb-4">We empower our clients to quickly and simply add a huge number of new subscribers with the use of bulk messaging. This software allows users to send several promotional text messages with just one click. Furthermore, sales and customer care teams can leverage our platform to engage in two-way text messaging with prospective customers.</div>

        <div class="h3 font-weight-bold mb-2">
          Achieve The New Standard
        </div>

        <div class="mb-4">Dedicated digital communication services are commonly employed by small businesses, nonprofit organizations, telehealth companies, and customer service and HR departments. Online firms and eCommerce marketers are also starting to use this groundbreaking tool to communicate with their customers.</div>

        <div class="h3 font-weight-bold mb-2">
          Why Do Businesses Choose Let’s Connect to Manage Their Communications?
        </div>

        <div class="mb-4">We're not afraid to admit that there are many bulk messaging providers available. Your time and money have value, and you deserve the best service at the most competitive cost. Here are some of the reasons why you should go with us:</div>

        <div class="row">
          <div class="col-md-4">
            <div class="featuresBig bg-white py-0">
              <div class="featureItem">
                <div class="h4 mt-0">We Are Human</div>
                <div class="small">Real people are with you every step of the way, from setting up your account to sharing best practices. If you require assistance at any time, you can easily contact an expert via phone, chat, or email.</div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="featuresBig bg-white py-0">
              <div class="featureItem">
                <div class="h4 mt-0">We are the Best-In-Class</div>
                <div class="small">With over a decade of experience assisting businesses and organizations in creating powerful connections with their audiences, we have received countless positive reviews and established lifelong business relationships.</div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="featuresBig bg-white py-0">
              <div class="featureItem">
                <div class="h4 mt-0">We are Simple to Implement</div>
                <div class="small">You have a lot on your plate, so you need software that is simple to set up and use. Let’s Connect requires no training and works on any computer, tablet, or phone.</div>
              </div>
            </div>
          </div>
        </div>

        <div class="section-subtitle text-center mt-5 mb-4">Services</div>
        <div class="section-title text-center mb-5">SMS Messaging</div>

        <div class="h3 font-weight-bold">Use customized SMS texts to communicate directly with your contacts.</div>
        <div class="mb-2">SMS is a mobile marketing tool that businesses rely on every day to expand their reach, streamline their processes, and increase their profits. An SMS message must be no more than 160 characters and is ideal for two-way texting. </div>

        <div class="mb-2">You may send text messages that are longer than 160 characters or contain photos, audio, or video, but these are unique from SMS and are known as MMS (Multimedia Messaging Service) communications.</div>

        <div class="mb-2">While the word "SMS" refers to text-only messages of 160 characters or less, it is important to note that it has also become a colloquial phrase for text marketing in general.</div>

        <div class="h3 font-weight-bold mt-5">How Our Clients Use SMS Messaging</div>
        <ul class="pl-5">
          <li><b>Alerts and Notifications: </b>SMS is most often used to send notifications and alerts because with a 98% open rate, you can be confident that your message will be noticed.</li>
          <li><b>Instant Messaging: </b>Over 70% of text messages are read within 10 minutes of being received. When it's vital that your message be read as soon as possible, texting is the only option.</li>
          <li><b>Payment Alternatives: </b>Sending scheduled or automated payment reminders can help you save money while turning red payments green.</li>
          <li><b>Alerts and Notifications: </b>SMS is most often used to send notifications and alerts because with a 98% open rate, you can be confident that your message will be noticed.</li>
        </ul>

        <div class="mb-2">Before you begin, you should be aware that SMS marketing is permission-based. You may only SMS a number if the individual wishes to hear from you in this manner. The goal is to meet prospective clients at the point of convenience.</div>

        <div class="h3 font-weight-bold mt-5">Here's how you can begin using SMS marketing:</div>
        <ul class="pl-5">
          <li><b>Step 1: </b> Sign up with our bulk text messaging software.</li>
          <li><b>Step 2: </b> Set up a dedicated phone number for your company.</li>
          <li><b>Step 3: </b> With a few clicks, import your existing contact list and send an opt-in text to obtain express written authorization for future communications</li>
          <li><b>Step 4: </b> Launch your first text marketing campaign. Let’s Connect allows you to create tailored text campaigns aimed toward your whole client base or at specific target audiences. Texts may also be planned months in advance, delivered straight from your app through API, disseminated via email to SMS, and many other options.</li>
          <li><b>Step 5: </b> Create a branded sign-up form on your website to begin collecting phone numbers.</li>
        </ul>

        <div class="section-subtitle text-center mt-5 mb-4">Services</div>
        <div class="section-title text-center mb-5">MMS Marketing Made Easy</div>

        <div class="h3 font-weight-bold mt-5">What is MMS Marketing?</div>
        <div class="mb-2">MMS marketing is a type of mobile advertising that allows you to deliver rich material to your consumers such as photographs, videos, and audio samples. MMS stands for "Multimedia Messaging Service" and is an improved version of regular SMS. MMS, like any other kind of bulk text messaging, requires prior authorization before you can begin sending anything to your clients.</div>

        <div class="h3 font-weight-bold mt-5">How is MMS Different From SMS?</div>
        <div class="mb-2">MMS, often known as "image messaging," has a few advantages over normal SMS.</div>
        <div class="mb-2">These improvements include picture support and greater character lengths in messages.</div>

        <div class="h3 font-weight-bold mt-5">What distinguishes MMS from SMS?</div>
        <div class="mb-2">MMS may be thought of as an improved form of SMS. While we may refer to both as basic text messages in everyday life, there are two essential differences to note:</div>
        <ul class="pl-5">
          <li>Multimedia messaging allows for the incorporation of media materials such as photographs, video, and audio. In contrast, text is the sole format supported by standard SMS messages.</li>
          <li>The character limit for MMS messages is substantially higher. MMS can store up to 1,600 characters of text, whereas SMS can only store 160.</li>
        </ul>

        <div class="mb-2">Some people dispute MMS's potential ROI. However, thousands of firms across all industries indicate that MMS marketing increases engagement by up to 250% over traditional SMS marketing.</div>

        <div class="h3 font-weight-bold mt-5">Why is MMS Marketing a Good Option?</div>
        <div class="mb-2">MMS marketing is an excellent solution for businesses looking to truly showcase their services and capabilities for the consumer.</div>

        <div class="mb-2">In reality, those businesses who select MMS typically rely on four major advantages:</div>
        <ol class="pl-5">
          <li><b>MMS Offers A More Comprehensive Customer Experience:</b> MMS marketing offers a far richer consumer experience than ordinary SMS texting. A picture is worth a thousand words and will always be more visually appealing than a message that is merely text.</li>
          <li><b>MS Encourages Increased Participation:</b> Because multimedia communications captivate people's attention, it automatically generates increased interaction. Not only do more individuals read and interact with the messages, but they are also up to eight times more likely to be shared with others.</li>
          <li><b>MMS Facilitates Better Redemption:</b> More participation equals more redemptions. Combining your text blasts with a fantastic graphic leads to higher response rates, which is precisely what our MMS marketing service consumers are finding.</li>
          <li><b>MMS Allows Longer Messages:</b> You can't always explain what you need to in a 160-character block of text. Multimedia messaging allows you a lot more room to spread your message.</li>
        </ol>

        <div class="mb-2">Begin your MMS marketing campaign right away! Our knowledgeable staff is here to answer any queries you may have.</div>

        <div class="mb-2">Be everywhere your customers are by taking advantage of our digital text messaging services.</div>

        <div class="section-subtitle text-center mt-5 mb-4">Services</div>
        <div class="section-title text-center mb-5">Email Communications, Marketing, & Support</div>

        <div class="mb-2">Beautifully designed emails are a vital component of coordinating internal efforts, promoting your brand, and expanding your business. Professional email software has never been simpler.</div>

        <ul class="pl-5">
          <li><b>Customize your message:</b> Identify the key takeaway or vital logistical information you want your recipients to access. Be it sales material or an appointment confirmation, Let’s Connect helps you make your voice heard.</li>
          <li><b>Design your email:</b> In no time, you can create a professional-looking email! Start with an email template collection or create a fresh design from scratch. Use the straightforward drag-and-drop builder to add the blocks and styles you want to fit your brand and impress your audience properly.</li>
          <li><b>Customize your content:</b> Customization allows you to dynamically add text such as your contact's name to your email content, or you can go much further with our sophisticated email personalization features.</li>
          <li><b>Choose your recipients:</b> Let’s Connect's email lists and contacts infrastructure allows you to be as creative as you want with how you reach your target audience. For a more focused approach, group contacts however you like and take your plan even further by picking even smaller groups depending on criteria that you specify (e.g. gender, region, purchase history, etc.).</li>
          <li><b>Send with confidence:</b> Finish your message and leave the heavy lifting to us. We are eager to help you find the optimal message, and machine learning-powered send time optimization can ensure your email is delivered at the optimal moment with the press of a button. Our smart features ensure that you feel like you have a Ph.D. in digital communications!</li>
        </ul>

        <div class="mb-2">Be everywhere your customers are by taking advantage of our email marketing services.</div>

        <div class="section-subtitle text-center mt-5 mb-4">Services</div>
        <div class="section-title text-center mb-5">Voice Communications</div>

        <div class="mb-2">At Let’s Connect, we understand that written communication sometimes falls short of conveying what you mean to say. Verbal communication provides unique human-factor advantages over text and email, such as:</div>

        <ul class="pl-5">
          <li>Transmitting your voice, tone, and inflection, thereby minimizing misunderstandings and miscommunication.</li>
          <li>Affording you the capability of rapid, collaborative communication, without the time loss that typing out a message inevitably causes.</li>
          <li>Reminding you, your colleagues, and your clients that there is a real person on the other end of the line. Voice messaging retains the human factors inherent to business growth and success.</li>
        </ul>

        <div class="mb-2">Hear each other loud and clear by leveraging our voice communication services.</div>

        <div class="section-subtitle text-center mt-5 mb-4">Services</div>
        <div class="section-title text-center mb-5">Video Conferencing Communications</div>

        <div class="mb-2">In the world of virtual business communications, video conferencing reigns supreme. Video is the only medium that leverages all factors inherent to an in-person meeting; however, you multiply that capability with a global reach.</div>

        <div class="mb-2">From team and staff meetings to client consultations and seminars, video conferencing is a broadly-applicable force multiplier. Some of the benefits include:</div>

        <ul class="pl-5">
          <li>Capturing your voice, facial expressions, and body language, providing the most holistic virtual business communication experience in existence.</li>
          <li> Offering you and your team the opportunity to share notes, slides, screen images, and workflows in real time. The digital infrastructure of Let’s Connect’s video conferencing software means you are communicating with immediate access to your device’s native software resources and files.</li>
          <li>Preserving the human element. Whether you are on different floors or in another country, Let’s Connect allows your business to feel and operate like you are merely on the other side of the conference table.</li>
        </ul>

        <div class="mb-2">Visualize the future of your company with our video conferencing services.</div>
      </div>
      <!-- <div class="page-header page-header-small">
        <div
          class="page-header-image"
          data-parallax="true"
          :style="{'background-image': 'url('+require('../../../assets/lc_img/product_background.jpg')+')'}"
        />
        <div class="content-center mt-4">
          <div class="container">
            <h1 class="title">
              Our Services
            </h1>
            <div class="text-center">
              <h4>Communicate with your customers and staff like never before on SMS, Voice, Email, Rich Media and so much more.</h4>
            </div>
            <div class="getStartedButtonWrapper">
              <button
                class="getStarted"
                @click="$router.push('/register')"
              >
                Get Started!
              </button>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="section section-about-us">
        <div class="container">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto text-center">
              <h2 class="title">
                Let's Connect Platform
              </h2>
              <h5 class="description">
                Refining communications with software
              </h5>
            </div>
          </div>
          <div class="separator separator-primary" />
          <div class="section-story-overview">
            <div class="row">
              <div class="col-md-4 serviceCardOuterWrapper mx-auto">
                <div class="serviceCardInnerWrapper smsService">
                  <img
                    class="serviceCardIconImg"
                    src="../../../assets/lc_img/sms_service.svg"
                    alt=""
                  >
                  <p class="cardHeader">
                    SMS Service
                  </p>
                  <p class="smsCardSubHeader">
                    Some summarised information on this service will go here as a description
                  </p>
                  <button
                    class="cardActionBtn"
                    @click="$router.push('/sms-service')"
                  >
                    More Info
                  </button>
                </div>
              </div>
              <div class="col-md-4 serviceCardOuterWrapper mx-auto">
                <div class="serviceCardInnerWrapper mmsService">
                  <img
                    class="serviceCardIconImg"
                    src="../../../assets/lc_img/mms_service.svg"
                    alt=""
                  >
                  <p class="cardHeader">
                    MMS Service
                  </p>
                  <p class="smsCardSubHeader">
                    Some summarised information on this service will go here as a description
                  </p>
                  <button
                    class="cardActionBtn"
                    @click="$router.push('/mms-service')"
                  >
                    More Info
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pricingWrapper">
        <div class="pricingIconWrapper">
          <i class="fas fa-hand-holding-usd" />
        </div>
        <p class="pricingHeader">
          Pricing
        </p>
        <button
          class="viewPlansBtn"
          @click="$router.push('/plans')"
        >
          VIEW PLANS
        </button>
      </div>

      <div class="main">
        <div class="contact-content">
          <div class="container">
            <div class="row">
              <div class="col-md-5 ml-auto mr-auto">
                <h2 class="title">
                  Send us a message
                </h2>
                <p class="description">
                  You can contact us with anything related to our Products. We'll get in touch with you as soon as possible.
                  <br>
                  <br>
                </p>
                <form
                  id="contact-form"
                  role="form"
                  method="post"
                >
                  <label>Your name</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="now-ui-icons users_circle-08" /></span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Your Name..."
                      aria-label="Your Name..."
                      autocomplete="name"
                    >
                  </div>
                  <label>Email address</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="now-ui-icons ui-1_email-85" /></span>
                    </div>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Email Here..."
                      aria-label="Email Here..."
                      autocomplete="email"
                    >
                  </div>
                  <label>Phone</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="now-ui-icons tech_mobile" /></span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Number Here..."
                      autocomplete="number"
                    >
                  </div>
                  <div class="form-group">
                    <label>Your message</label>
                    <textarea
                      id="message"
                      name="message"
                      class="form-control"
                      rows="6"
                    />
                  </div>
                  <div class="submit text-center">
                    <input
                      type="submit"
                      class="btn btn-primary btn-raised btn-round"
                      value="Contact Us"
                    >
                  </div>
                </form>
              </div>
              <div class="col-md-5 ml-auto mr-auto">
                <div class="info info-horizontal">
                  <div class="icon icon-primary">
                    <i class="now-ui-icons location_pin" />
                  </div>
                  <div class="description">
                    <h4 class="info-title">
                      Find us at the office
                    </h4>
                    <p>
                      Bld Mihail Kogalniceanu, nr. 8,
                      <br> 7652 Bucharest,
                      <br> Romania
                    </p>
                  </div>
                </div>
                <div class="info info-horizontal">
                  <div class="icon icon-primary">
                    <i class="now-ui-icons tech_mobile" />
                  </div>
                  <div class="description">
                    <h4 class="info-title">
                      Give us a ring
                    </h4>
                    <p>
                      Michael Jordan
                      <br> +40 762 321 762
                      <br> Mon - Fri, 8:00-22:00
                    </p>
                  </div>
                </div>
                <div class="info info-horizontal">
                  <div class="icon icon-primary">
                    <i class="business_briefcase-24 now-ui-icons" />
                  </div>
                  <div class="description">
                    <h4 class="info-title">
                      Legal Information
                    </h4>
                    <p>
                      Creative Tim Ltd.
                      <br> VAT &middot; EN2341241
                      <br> IBAN &middot; EN8732ENGB2300099123
                      <br> Bank &middot; Great Britain Bank
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <public-footer />
  </div>
</template>

<script>
  export default {
    name: 'ServicesPage',

    components: {
      PublicHeader: () => import('../components/PublicHeader'),
      PublicFooter: () => import('../components/PublicFooter'),
    },

    data: () => ({
      isUserLoggedIn: false,
    }),
    computed: {},
    created () {
      this.checkIfUserLoggedIn()
    },
    methods: {
      checkIfUserLoggedIn () {
        const username = localStorage.getItem('username')
        if (username && username.length) {
          this.isUserLoggedIn = true
        }
      },
      redirectToPage (path) {
        window.location = window.location.origin + path
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css";
@import '../../../assets/bootstrap.min.css';
@import '../../../assets/now-ui-kit.css';
.page-header-image {
  // background-image: radial-gradient(circle 1693px at 100% 100%, var(--clr-secondary) 0%, var(--clr-secondary) 33%, var(--clr-accent-dark) 66%);
  background-color: var(--clr-accent);
}
.page-header:before {
  background-color: rgba(8, 8, 8, 0);
}
.page-header.page-header-small {
  max-height: unset!important;
  // height: 600px!important;
}
.getStartedButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .getStarted {
    margin-top: 15px;
    background-color: rgb(233, 178, 0);
    color: rgb(255, 255, 255);
    padding: 16px 52px;
    font-size: 1.5rem;
    font-weight: 400;
    border-radius: 6px;
  }
}
.serviceCardOuterWrapper {
  padding: 15px 36px;
  .serviceCardInnerWrapper {
    height: 400px;
    box-shadow: 0px 0px 100px rgba(0,0,0,0.15);
    border-radius: 6px;
    background-color: white;
    display: flex;
    padding: 30px 40px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .serviceCardIconImg {
      width: 130px;
      height: 130px;
    }
    .cardHeader {
      margin-top: 35px;
      font-size: 1.3rem;
    }
    .smsCardSubHeader {
      text-align: center;
      font-size: 0.9rem;
      color: grey;
    }
    .cardActionBtn {
      margin-top: 35px;
      cursor: pointer;
      padding: 6px 30px;
      font-size: 0.9rem;
      border: 1px solid rgb(0, 128, 255);
      border-radius: 6px;
      box-shadow: 7px 7px 10px rgba(0,0,0,0.05);
    }
    &.smsService {
      .cardActionBtn {
        border: 1px solid #61BCF5;
        &:hover {
          background-color: #61BCF5;
          color: white;
        }
      }
    }
    &.mmsService {
      .cardActionBtn {
        border: 1px solid #420FB7;
        &:hover {
          background-color: #420FB7;
          color: white;
        }
      }
    }
    &.rmService {
      .cardActionBtn {
        border: 1px solid #FF6A4B;
        &:hover {
          background-color: #FF6A4B;
          color: white;
        }
      }
    }
    &.voiceService {
      .cardActionBtn {
        border: 1px solid #FC6DE5;
        &:hover {
          background-color: #FC6DE5;
          color: white;
        }
      }
    }
  }
}
.pricingWrapper {
  // background-color: rgb(233, 178, 0);
  background-image: url('https://png.pngtree.com/thumb_back/fh260/background/20200714/pngtree-abstract-line-background-design-image_351709.jpg');
  background-position: center;
  background-size: cover;
  height: 300px;
  width: 100vw;
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  .pricingIconWrapper {
    width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60px;
    background-color: white;
    border-radius: 60px;
    color: rgb(233, 178, 0)!important;
    * {
      color: rgb(233, 178, 0)!important;
    }
    font-size: 2.3rem;
  }
  .pricingHeader {
    font-size: 2rem;
    margin-top: 10px;
    color: white;
  }
  .viewPlansBtn {
    margin-top: 35px;
    cursor: pointer;
    padding: 10px 40px;
    font-size: 0.9rem;
    color: white;
    background-color: rgb(219, 186, 0);
    border-radius: 6px;
    box-shadow: 7px 7px 10px rgba(0,0,0,0.05);
  }
}
.info-horizontal {
  margin-bottom: -8rem;
}
</style>
